@import "../../node_modules/soft-ui-dashboard/assets/scss/soft-ui-dashboard.scss";
@import "../../node_modules/soft-ui-dashboard/assets/css/nucleo-svg";
@import "../../node_modules/soft-ui-dashboard/assets/css/nucleo-icons";

.navbar-vertical.navbar-expand-xs .navbar-collapse {
  height: auto;
}

.navbar .sidenav-toggler-inner {
  width: 18px;
}

.navbar .sidenav-toggler-inner .sidenav-toggler-line:not(:last-child) {
  margin-bottom: 3px;
}

.navbar .sidenav-toggler-inner .sidenav-toggler-line {
    transition: all .15s ease;
    background: #67748e;
    border-radius: .125rem;
    position: relative;
    display: block;
    height: 2px;
}

.errorlist {
  list-style: none;
}

.errorlist>li {
  float: left;
}

.errorlist::before {
  float: left;
  content: "Error: ";
  padding-right: 0.2em;
}
